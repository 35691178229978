@tailwind base;
@tailwind components;
@tailwind utilities;

:where(html, body, #root) {
  height: 100%;
}
body {
  color: #0E0A09;
}

.grid-row-init-height {
  grid-template-rows: 0fr;
}

.driver-form-child-container {
  @apply grid sm:grid-cols-2 xl:grid-cols-3 gap-y-[30px] gap-x-10 xl:gap-x-20;
}

.message-triangle {
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
}

